@media screen and (min-width: 768px) {
  .in-decorate .content {
    padding: 1.12rem 0.9rem 0;
  }
  .in-decorate .content .wyzs {
    font-size: 20px;
  }
  .in-decorate .content .dw {
    font-size: 36px;
    padding-bottom: 0.2rem;
  }
  .in-decorate .num-list {
    padding: 0.8rem 0.9rem 0;
  }
  .in-decorate .num-list li .box {
    padding: 0;
  }
  .in-decorate .num-list li .text {
    padding-right: 0.5rem;
    line-height: 18px;
  }
  .in-decorate .link-list li {
    width: 25%;
  }
  .in-decorate .link-list li .en {
    line-height: 1;
    padding: 0.1rem;
    height: 28px;
  }
  .in-decorate .link-list li .cn {
    font-size: 20px;
    line-height: 1;
    padding: 0.1rem;
  }
  .in-title .cn {
    font-size: 30px;
    line-height: 40px;
  }
  .in-title .en {
    font-size: 18px;
    line-height: 28px;
  }
  .index-tab {
    margin: 38px 0 47px;
  }
  .project-box .content {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 6.6rem;
    right: 2.6rem;
    padding: 0.68rem 0.5rem;
  }
  .inner-banner {
    height: 200px;
  }
  .inner-banner .content {
    margin: 77px auto 0;
  }
  .inner-banner .content .en {
    font-size: 36px;
  }
  .inner-banner .content .cn {
    font-size: 20px;
  }
  .inner-layout.nav .main {
    border-bottom: 2px solid #f8f8f8;
  }
  .inner-layout .inner-nav .cur-nav {
    display: none;
  }
  .inner-layout .inner-nav .nav-list {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    position: static;
    -webkit-box-shadow: none;
            box-shadow: none;
    width: 100%;
    max-width: 770px;
  }
  .inner-layout .inner-nav .nav-list li {
    border-bottom: none;
    padding: 17px 0.44rem 17px 0;
  }
  .inner-layout.log-nav .inner-nav .nav-list {
    max-width: 880px;
  }
  .inner-layout .crumbs {
    padding: 18px 0 18px 0.3rem;
  }
  .history-content .year {
    font-size: 48px;
    margin-top: 0.54rem;
  }
  .history-content dd {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .main-title .cn {
    font-size: 20px;
  }
  .main-title .en {
    font-size: 30px;
  }
  .doc-list li {
    font-size: 20px;
    padding: 0.54rem;
    padding-left: 0;
  }
  .doc-list a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .doc-list .title {
    padding: 0 0.3rem;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
  .doc-list .btn {
    display: block;
  }
  .project-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  .project-header .main-title {
    margin-bottom: 0;
  }
  .project-header .search-project {
    width: 6.48rem;
  }
  .project-header .search-project input {
    height: 1.08rem;
    line-height: 1.08rem;
  }
  .pro-list {
    max-width: none;
  }
  .pro-list li {
    width: 49%;
  }
  .culture-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .culture-item dt {
    width: 300px;
    -ms-flex-negative: 0;
        flex-shrink: 0;
  }
  .culture-item dt .title2 {
    font-size: 22px;
  }
  .culture-item dd {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
  .video-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .video-list .img-box:after {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
  .video-list li {
    width: 49%;
    max-width: 370px;
  }
  .video-list li:hover .img-box:after {
    visibility: visible;
    opacity: 1;
  }
  .video-list li:hover .name {
    color: #96231c;
  }
  .top-news a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .top-news a .img-box {
    width: 58.4%;
    max-width: 450px;
    -ms-flex-negative: 0;
        flex-shrink: 0;
  }
  .top-news a .title {
    font-size: 20px;
    display: -webkit-box;
    line-height: 28px;
    height: 56px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-bottom: 12px;
  }
  .top-news a .text {
    display: -webkit-box;
    line-height: 22px;
    height: 66px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
  .top-news a .day {
    font-size: 30px;
  }
  .top-news a .year {
    font-size: 18px;
  }
  .top-news a .new {
    padding: 0.8rem 0.53rem 0;
  }
  .top-news a .dot {
    padding-right: 0.53rem;
  }
  .new-list li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .new-list li a .date {
    width: 120px;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    font-size: 20px;
  }
  .new-list li a .title {
    font-size: 20px;
    padding-top: 0;
  }
  .new-list li a .new {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
  .noflex a {
    display: block !important;
  }
  .noflex a .img-box {
    width: 100%;
  }
  .noflex a .title {
    font-size: 18px;
  }
  .noflex a .text {
    display: -webkit-box;
    line-height: 22px;
    height: 44px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .sw-name {
    font-size: 20px;
  }
  .honor-list {
    margin-right: -22px;
  }
  .honor-list li {
    margin: 0;
    margin-right: 22px;
  }
  .new-header .title {
    font-size: 22px;
  }
  .new-header .date {
    font-size: 20px;
  }
  .partner-list li {
    width: 20%;
  }
  .company-stock .stock-info {
    top: 55px;
    left: 60px;
  }
  .company-stock .trade-info {
    max-width: 400px;
    left: 260px;
    top: 114px;
  }
  .company-stock .trade-info li {
    width: auto;
  }
  .company-stock .trade-info .num {
    font-size: 22px;
  }
}
@media screen and (min-width: 568px) {
  .in-area .syly {
    max-width: none;
    margin: 0;
  }
  .in-news {
    padding: 0 0.3rem 1.46rem;
  }
  .in-news .innews-list {
    width: auto;
  }
}
