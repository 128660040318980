@media screen and (min-width: 1200px) {
  .header-nav .nav-list {
    padding-right: 2.33rem;
  }
  .inner-layout.footer {
    margin-right: 0;
    padding-right: 80px;
  }
  .in-area .tab-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .in-area .tab-list li {
    width: 25%;
    position: relative;
  }
  .in-area .tab-list li:after {
    content: '';
    background-color: #96231c;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
  .in-area .tab-list .item-title {
    position: relative;
    z-index: 6;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
  .in-area .tab-list .more-info {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 4;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translate(0, 50%);
            transform: translate(0, 50%);
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
    background-color: transparent;
  }
  .in-area .tab-list li:hover .img-box {
    display: none;
  }
  .in-area .tab-list li:hover .item-title {
    position: absolute;
    padding-bottom: 0;
    width: 100%;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    color: #fff;
  }
  .in-area .tab-list li:hover .item-title .en {
    width: 130px;
    color: #fff;
  }
  .in-area .tab-list li:hover .item-title .en:after {
    background-color: #fff;
  }
  .in-area .tab-list li:hover:after,
  .in-area .tab-list li:hover .more-info {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  .project-box .content {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 660px;
    right: 260px;
    padding: 68px 50px;
  }
  .inner-banner,
  .inner-layout {
    margin-right: 80px;
  }
}
@media screen and (min-width: 1280px) {
  .in-decorate .link-list {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    margin: 10px 0 0 -25%;
    z-index: 1;
  }
  .in-news {
    padding: 0 0 146px;
  }
  .in-news .innews-list {
    padding-top: 90px;
  }
  .in-news .innews-list ul {
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
  .in-news .innews-list ul li {
    max-width: 290px;
  }
}
@media screen and (min-width: 1320px) {
  .inner-layout .main-content,
  .inner-layout .inner-nav .nav-list {
    padding-left: 0;
  }
}
@media screen and (min-width: 1450px) {
  .in-decorate .link-list {
    margin-top: 1.5rem;
  }
}
@media screen and (min-width: 1800px) {
  .in-decorate .content {
    padding: 2rem 1.62rem 0;
  }
  .in-decorate .num-list {
    padding: 1.42rem 1.62rem 0;
  }
  .in-decorate .link-list {
    width: 892px;
    margin: 1.35rem 0 0 -223px;
  }
  .in-decorate .link-list li {
    padding: 0.95rem 0;
    width: 223px;
  }
  .in-decorate .link-list li .en {
    height: 14px;
    padding: 0.2rem 0 0.3rem;
  }
}
