.clearfix {
  *zoom: 1;
}
.clearfix:after {
  content: '';
  display: block;
  font-size: 0;
  line-height: 0;
  visibility: hidden;
  clear: both;
}
.block-img {
  display: block;
  width: 100%;
}
.pre-oh {
  position: relative;
  overflow: hidden;
}
.tal {
  text-align: left !important;
}
.post {
  position: static !important;
  -webkit-transform: translate(0, 0) !important;
          transform: translate(0, 0) !important;
}
.bgf6 {
  background-color: #f6f6f6;
}
.bgf {
  background-color: #fff;
}
.web {
  max-width: 1234px;
  margin: 0 auto;
  padding: 0 0.3rem;
}
.pb22 {
  padding-bottom: 22px;
}
.mt22 {
  margin-top: 22px;
}
.pb18 {
  padding-bottom: 18px;
}
.pt30 {
  padding-top: 0.53rem;
}
.ptb35 {
  padding: 0.64rem 0;
}
.theme-color {
  color: #96231c;
}
.c6 {
  color: #c6c6c6;
}
.c96 {
  color: #969696;
}
.c7e {
  color: #7e7e7e;
}
.c4e {
  color: #4e4e52;
}
.fz18 {
  font-size: 16px;
}
@media screen and (min-width: 768px) {
  .fz18 {
    font-size: 18px;
  }
}
.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding: 10px 15px;
  z-index: 600;
  background-color: #fff;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.header.trans {
  background-color: transparent;
}
.header.trans .menu-icon {
  background-color: transparent;
}
.header.active {
  background-color: #fff;
}
.header-nav .nav-list.m {
  display: none;
  position: fixed;
  top: 56px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  border-top: 1px solid #ddd;
  padding: 10px 15px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.header-nav .nav-list.m li p a {
  display: block;
  padding: 10px 0;
  font-size: 16px;
  border-bottom: 1px solid #ddd;
}
.header-nav .nav-list.m li.is-sub p {
  position: relative;
}
.header-nav .nav-list.m li.is-sub p:after {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -6px;
  width: 8px;
  height: 8px;
  border: 2px solid transparent;
  border-top-color: #ddd;
  border-right-color: #ddd;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  right: 10px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.header-nav .nav-list.m li.is-sub p.active:after {
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
}
.header-nav .nav-list.m .sub {
  padding: 10px 0;
  display: none;
}
.header-nav .nav-list.m .sub a {
  display: block;
  line-height: 30px;
  text-indent: 2em;
}
.header-logo {
  width: 80px;
}
.menu-icon {
  cursor: pointer;
  width: 36px;
  height: 36px;
  padding: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.menu-icon span {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #96231c;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.menu-icon.active span:nth-child(1) {
  -webkit-transform: rotate(45deg) translate(0, 11px);
          transform: rotate(45deg) translate(0, 11px);
}
.menu-icon.active span:nth-child(2) {
  -webkit-transform: scale(0, 0);
          transform: scale(0, 0);
}
.menu-icon.active span:nth-child(3) {
  -webkit-transform: rotate(-45deg) translate(0, -11px);
          transform: rotate(-45deg) translate(0, -11px);
}
.header.scroll {
  background-color: #fff;
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}
.inner-layout.footer {
  background-color: #242424;
}
.inner-layout.footer .main {
  display: none;
}
.inner-layout.footer .slider {
  background-color: transparent;
}
.inner-layout.footer .company-info {
  color: rgba(161, 161, 161, 0.8);
  font-size: 13px;
  line-height: 22px;
  margin: 0 auto;
}
.inner-layout.footer .copy-tel {
  padding-bottom: 22px;
}
.inner-layout.footer .copy-tel a {
  color: rgba(161, 161, 161, 0.8);
}
.inner-layout.footer .copy-tel a:hover {
  text-decoration: underline;
}
.inner-layout.footer .follow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.inner-layout.footer .follow .wx {
  margin-left: 15px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 32px;
  height: 32px;
  background: url(../../static/images/ximg53.png) no-repeat center;
  background-size: 100%;
}
.ewm-box {
  position: relative;
  cursor: pointer;
}
.ewm-box img {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: 0;
  width: 120px;
  max-width: none;
  z-index: 50;
  -webkit-transform: translate(-50%, 50%) scale(0, 0);
          transform: translate(-50%, 50%) scale(0, 0);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  border: 2px solid #fff;
}
.ewm-box:hover img {
  -webkit-transform: translate(-50%, -32%) scale(1, 1);
          transform: translate(-50%, -32%) scale(1, 1);
  visibility: visible;
  opacity: 1;
}
.full-nav {
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  z-index: -100;
  background-color: #fff;
}
.full-nav .left {
  width: 30.625%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  z-index: 10;
}
.full-nav .left .contact {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  background-color: #fff;
  position: relative;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translate(0, -100%);
          transform: translate(0, -100%);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.full-nav .left .contact .info {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
}
.full-nav .left .contact .addr-tip {
  color: #666;
  font-size: 16px;
  padding-bottom: 50px;
  background: url(../../static/images/ximg55.png) no-repeat center 38px;
}
.full-nav .left .contact .contact-tel {
  font-size: 30px;
  padding-top: 15px;
  color: #96231c;
}
.full-nav .left .contact .more-tel {
  display: block;
  border-bottom: 1px solid #96231c;
  padding: 6px 0;
  width: 84px;
  font-size: 12px;
  margin: 0 auto;
  color: #96231c;
}
.full-nav .left .share {
  height: 300px;
  background-color: #96231c;
  position: relative;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translate(100%, 0);
          transform: translate(100%, 0);
  -webkit-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
}
.full-nav .left .icon-box {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
}
.full-nav .left .icon-box a {
  display: inline-block;
  width: 44px;
  height: 44px;
  margin: 0 10px;
  background: no-repeat center;
}
.full-nav .left .icon-box a.wx {
  background-image: url(../../static/images/ximg46.png);
  background-size: 20px;
}
.full-nav .left .icon-box a.in {
  background-image: url(../../static/images/ximg54.png);
  background-size: 18px;
}
.full-nav .left .icon-box a.wb {
  background-image: url(../../static/images/ximg48.png);
  background-size: 22px;
}
.full-nav .right {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  background-color: #333;
  height: 100%;
  position: relative;
  z-index: 5;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translate(-100%, 0);
          transform: translate(-100%, 0);
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  overflow: hidden;
}
.full-nav .right .nav-list {
  padding: 192px 0 0 26.87%;
  width: 100%;
}
.full-nav .right .nav-list li {
  margin-bottom: 26px;
  position: relative;
}
.full-nav .right .nav-list li p {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.full-nav .right .nav-list li p small {
  display: inline-block;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.2);
  vertical-align: top;
  margin-top: 4px;
  margin-right: 15px;
}
.full-nav .right .nav-list li p a {
  font-size: 22px;
  color: rgba(255, 255, 255, 0.3);
}
.full-nav .right .nav-list .sub {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 170px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 122px;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translate(100%, -50%) scale(0, 1);
          transform: translate(100%, -50%) scale(0, 1);
  -webkit-transform-origin: right;
          transform-origin: right;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.full-nav .right .nav-list .sub:after {
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  width: 93px;
  height: 2px;
  background-color: #fff;
}
.full-nav .right .nav-list .sub a {
  color: #999;
  margin: 0 12px 5px;
}
.full-nav .right .nav-list .sub a:hover {
  color: #fff;
}
.full-nav .right .nav-list li:hover p small {
  color: rgba(255, 255, 255, 0.5);
}
.full-nav .right .nav-list li:hover p a {
  color: #fff;
}
.full-nav .right .nav-list li:hover .sub {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(0, -50%) scale(1, 1);
          transform: translate(0, -50%) scale(1, 1);
}
.full-nav.enter {
  z-index: 590;
  visibility: hidden;
  opacity: 1;
}
.full-nav.enter .contact,
.full-nav.enter .share,
.full-nav.enter .right {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}
.full-nav.level .contact {
  -webkit-transform: translate(0, 100%);
          transform: translate(0, 100%);
}
.full-nav.level .share {
  -webkit-transform: translate(-100%, 0);
          transform: translate(-100%, 0);
}
.full-nav.level .right {
  -webkit-transform: translate(100%, 0);
          transform: translate(100%, 0);
}
.clear-header {
  height: 56px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.index-banner .swiper-pagination-bullet-active {
  background-color: #96231c;
}
.in-decorate {
  margin-top: 0.53rem;
}
.in-decorate .img-box {
  position: relative;
  height: 300px;
  overflow: hidden;
}
.in-decorate .img-box img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: block;
  max-width: none;
  min-width: 100%;
  min-height: 100%;
}
.in-decorate .content {
  padding: 0.6rem 0.3rem;
  color: #666;
  font-size: 14px;
}
.in-decorate .content .dw {
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 0.2rem;
  color: #333;
}
.in-decorate .content .dw span {
  margin-right: 0.3rem;
}
.in-decorate .content .text {
  max-width: 664px;
}
.in-decorate .num-list li {
  width: 33.3%;
  max-width: 183px;
  float: left;
}
.in-decorate .num-list li .box {
  padding: 0 0.3rem;
}
.in-decorate .num-list li .icon {
  width: 40px;
}
.in-decorate .num-list li .wyzs {
  font-size: 18px;
}
.in-decorate .num-list li .num {
  font-weight: 700;
  font-size: 36px;
  padding: 0.3rem 0 0.14rem;
}
.in-decorate .num-list li .text {
  color: #999;
}
.in-decorate .link-list {
  background-color: #fff;
  margin-top: 0.75rem;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.in-decorate .link-list li {
  width: 50%;
  float: left;
  text-align: center;
  padding: 0.45rem 0 0.38rem;
  font-size: 14px;
  -webkit-box-shadow: -10px -10px 20px rgba(0, 0, 0, 0.1);
          box-shadow: -10px -10px 20px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}
.in-decorate .link-list li .icon {
  width: 40px;
  margin: 0 auto 5px;
  position: relative;
}
.in-decorate .link-list li .icon img {
  left: 0;
  top: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.in-decorate .link-list li .icon img.show {
  position: absolute;
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
  opacity: 1;
}
.in-decorate .link-list li .icon img.hiden {
  position: static;
  -webkit-transform: scale(0, 0);
          transform: scale(0, 0);
  opacity: 0;
}
.in-decorate .link-list li .en {
  color: #999;
}
.in-decorate .link-list li:hover {
  background-color: #96231c;
}
.in-decorate .link-list li:hover .icon img.show {
  position: static;
  -webkit-transform: scale(0, 0);
          transform: scale(0, 0);
  opacity: 0;
}
.in-decorate .link-list li:hover .icon img.hiden {
  position: absolute;
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
  opacity: 1;
}
.in-decorate .link-list li:hover .en,
.in-decorate .link-list li:hover .cn {
  color: #fff;
}
.in-title {
  padding-top: 0.5rem;
  text-align: center;
}
.in-title .cn {
  font-size: 18px;
}
.in-title .en {
  font-size: 16px;
  color: #666;
}
.index-tab {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0.38rem 0 0.47rem;
}
.index-tab.project-tab {
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
}
.index-tab li {
  width: 180px;
  color: #222;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.index-tab li:not(:last-child) {
  border-right: 1px solid #c9c9c9;
}
.index-tab li .icon {
  position: relative;
  margin-bottom: 2px;
  height: 32px;
}
.index-tab li .icon img {
  display: block;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: 0;
}
.index-tab li .icon .show {
  visibility: visible;
  opacity: 1;
}
.index-tab li .icon .hiden {
  position: absolute;
  visibility: hidden;
  opacity: 0;
}
.index-tab li:hover,
.index-tab li.active {
  color: #96231c;
}
.index-tab li:hover .icon .show,
.index-tab li.active .icon .show {
  position: absolute;
  visibility: hidden;
  opacity: 0;
}
.index-tab li:hover .icon .hiden,
.index-tab li.active .icon .hiden {
  visibility: visible;
  opacity: 1;
}
.in-area {
  background-color: #f0eee8;
}
.in-area .syly {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  max-width: 320px;
  margin: 0 auto;
  display: none;
}
.in-area .tab-list li:nth-child(2n) {
  background-color: #fff;
}
.in-area .tab-list li:nth-child(2n+1) {
  background-color: #f7f5f0;
}
.in-area .tab-list .item-title {
  padding: 0.47rem 0 0.65rem 0.65rem;
}
.in-area .tab-list .item-title .cn {
  font-size: 20px;
}
.in-area .tab-list .item-title .en {
  font-size: 16px;
  color: #666;
  position: relative;
}
.in-area .tab-list .item-title .en:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -0.2rem;
  width: 20px;
  height: 3px;
  background-color: #333;
}
.in-area .tab-list .more-info {
  background-color: #96231c;
  padding: 0.47rem;
}
.in-area .tab-list .more-info .text {
  color: #fff;
  margin-bottom: 0.44rem;
}
.in-area .tab-list .more-info .btn {
  display: block;
  color: #96231c;
  width: 146px;
  line-height: 43px;
  background: #fff url(../../static/images/ximg20.png) no-repeat 100px;
  background-size: 20px;
  border-radius: 25px;
  padding: 0 32px;
}
.project-box {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: none;
}
.project-box .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0.6rem 0.3rem;
  background-color: #fff;
}
.project-box .content .title {
  padding-right: 0.7rem;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.project-box .content .cn {
  font-size: 18px;
}
.project-box .content .en {
  font-size: 16px;
  color: #666;
  position: relative;
  padding-bottom: 0.2rem;
}
.project-box .content .en:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 20px;
  height: 2px;
  background-color: #96231c;
}
.project-box .content .info .text {
  color: #666;
  display: -webkit-box;
  line-height: 24px;
  height: 72px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.project-box .content .info .btn {
  display: block;
  border: 1px solid #dbdbdb;
  color: #666;
  width: 116px;
  line-height: 32px;
  position: relative;
  padding-right: 5px;
  text-align: center;
  margin-top: 0.5rem;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.project-box .content .info .btn:after {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -5px;
  width: 8px;
  height: 8px;
  border: 1px solid transparent;
  border-top-color: #aaa;
  border-right-color: #aaa;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  right: 25px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.project-box .content .info .btn:hover {
  border-color: #96231c;
  background-color: #96231c;
  color: #fff;
  padding-right: 40px;
}
.project-box .content .info .btn:hover:after {
  border-top-color: #fff;
  border-right-color: #fff;
  right: 15px;
}
.in-news {
  background-color: #f0eee8;
  padding-bottom: 1.46rem;
}
.in-news .innews-list {
  width: 290px;
  margin: 0 auto;
  padding-top: 0.9rem;
  max-width: 1400px;
}
.in-news .innews-list .pre-oh img {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.in-news .innews-list .title {
  font-size: 18px;
  color: #474747;
  display: -webkit-box;
  line-height: 30px;
  height: 60px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin: 22px 0;
  padding-left: 20px;
  position: relative;
}
.in-news .innews-list .title:after {
  content: '';
  position: absolute;
  left: 0;
  top: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #96231c;
}
.in-news .innews-list .text {
  margin-bottom: 36px;
  color: #666;
}
.in-news .innews-list .btn {
  display: block;
  width: 48px;
  height: 32px;
  border: 1px solid #96231c;
  position: relative;
  border-radius: 30px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  margin-bottom: 2px;
}
.in-news .innews-list .btn:after {
  content: '';
  right: 16px;
  position: absolute;
  top: 50%;
  margin-top: -5px;
  width: 8px;
  height: 8px;
  border: 1px solid transparent;
  border-top-color: #96231c;
  border-right-color: #96231c;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.in-news .innews-list a:hover .title,
.in-news .innews-list a:hover .text {
  color: #96231c;
}
.in-news .innews-list a:hover .btn {
  width: 60px;
}
.in-news .innews-list a:hover .pre-oh img {
  -webkit-transform: scale(1.2, 1.2);
          transform: scale(1.2, 1.2);
}
.inner-banner {
  height: 150px;
  padding: 0 0.3rem;
}
.inner-banner img {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  min-height: 100%;
  max-height: 100%;
  max-width: none;
}
.inner-banner .content {
  position: relative;
  max-width: 1120px;
  margin: 1.4rem auto 0;
  z-index: 4;
}
.inner-banner .content .en {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.3);
}
.inner-banner .content .cn {
  font-size: 14px;
  color: #8b8c8e;
}
.inner-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.inner-layout.nav {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}
.inner-layout .main {
  background-color: #fff;
}
.inner-layout .main-content {
  padding: 0 0.3rem 0.83rem;
}
.inner-layout .inner-nav {
  position: relative;
}
.inner-layout .inner-nav .cur-nav {
  color: #96231c;
  border-bottom: 2px solid #f8f8f8;
  padding: 0.2rem 0.3rem;
  position: relative;
}
.inner-layout .inner-nav .cur-nav:after {
  content: '';
  right: 0.3rem;
  position: absolute;
  top: 50%;
  margin-top: -5px;
  width: 8px;
  height: 8px;
  border: 1px solid transparent;
  border-top-color: #999;
  border-right-color: #999;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.inner-layout .inner-nav .cur-nav.active:after {
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
}
.inner-layout .inner-nav .nav-list {
  position: absolute;
  top: 100%;
  left: 0;
  padding: 0 0.3rem;
  background-color: #fff;
  width: 100%;
  -webkit-box-shadow: 0px 8px 20px -10px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 8px 20px -10px rgba(0, 0, 0, 0.2);
  z-index: 500;
  display: none;
}
.inner-layout .inner-nav .nav-list li {
  padding: 0.2rem 2em;
  border-bottom: 1px solid #f8f8f8;
}
.inner-layout .inner-nav .nav-list li a {
  display: block;
  color: #5d5d5d;
}
.inner-layout .inner-nav .nav-list li a:hover,
.inner-layout .inner-nav .nav-list li a.active {
  color: #96231c;
}
.inner-layout .crumbs {
  background-color: #96231c;
  padding: 0.2rem 0.3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.inner-layout .crumbs li {
  position: relative;
}
.inner-layout .crumbs li:not(:first-of-type) {
  padding-left: 0.6rem;
}
.inner-layout .crumbs li:not(:first-of-type):after {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -2px;
  left: 0.3rem;
  width: 0;
  height: 0;
  border: 3px solid transparent;
  border-top-color: rgba(255, 255, 255, 0.3);
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.inner-layout .crumbs a {
  color: rgba(255, 255, 255, 0.66);
}
.inner-layout .crumbs a.home {
  display: block;
  width: 10px;
  height: 10px;
  background: url(../../static/images/ximg35.png) no-repeat center;
  background-size: 100%;
}
.inner-layout .crumbs a:hover {
  text-decoration: underline;
}
.inner-layout .slider .content {
  padding: 1rem 0.3rem;
  max-width: 400px;
}
.inner-layout .inchina {
  padding: 0.46rem 0.3rem 0.28rem;
}
.inner-layout .inchina .title {
  font-size: 16px;
  padding-bottom: 0.1rem;
  font-weight: 400;
}
.inner-layout .inchina .subtitle {
  font-weight: 400;
  font-size: 18px;
  color: #96231c;
  padding-bottom: 0.1rem;
}
.inner-layout .inchina .info {
  color: #acacac;
  padding-bottom: 5px;
}
.inner-layout .china-map {
  width: 256px;
  margin: 0 auto;
}
.inner-layout .china-map2 {
  margin: 10px 0 0 0.3rem;
  padding-left: 0.53rem;
  position: relative;
}
.inner-layout .china-map2 img {
  display: block;
}
.stock-info {
  position: absolute;
  top: 20px;
  left: 0;
  width: 160px;
}
.stock-info .stock-code {
  color: #535353;
}
.stock-info .stock-name {
  color: #828283;
  margin-bottom: 12px;
}
.stock-info .market-value {
  padding-left: 24px;
  background: url(../../static/images/ximg58.png) no-repeat left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.stock-info .value {
  color: #f94122;
  margin-right: 10px;
  font-size: 20px;
}
.stock-info .unit {
  color: #fa4223;
  font-size: 12px;
}
.company-stock {
  position: relative;
  overflow: hidden;
  height: 240px;
  background: url(../../static/images/ximg19.jpg) no-repeat center;
  background-size: cover;
}
.company-stock .stock-info {
  top: 10px;
  left: 10px;
}
.company-stock .trade-info {
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
}
.company-stock .trade-info li {
  margin-bottom: 10px;
  width: 50%;
}
.company-stock .trade-info .tip {
  color: #828282;
}
.company-stock .trade-info .num {
  font-size: 18px;
  color: rgba(76, 75, 86, 0.6);
}
.main-title {
  margin: 0.9rem 0 0.3rem;
  position: relative;
}
.main-title .cn {
  font-size: 16px;
}
.main-title .en {
  font-size: 18px;
  color: #96231c;
}
.main-title .nav-tab {
  position: absolute;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.main-title .nav-tab a {
  font-size: 13px;
  padding: 10px 9px;
  color: #a1a1a1;
  background-color: #ececec;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.main-title .nav-tab a.active,
.main-title .nav-tab a:hover {
  background-color: #96231c;
  color: #fff;
}
.main-title .text {
  color: #969696;
  font-size: 13px;
  line-height: 22px;
}
.year-list {
  border-bottom: 1px solid #f6f6f6;
}
.year-list li {
  width: 40px;
  cursor: pointer;
  padding: 0.2rem 0;
  color: #bababa;
  font-size: 18px;
  position: relative;
}
.year-list li.active {
  color: #96231c;
}
.year-list li.active:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #96231c;
}
.history-content {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}
.history-content .year {
  color: #96231c;
  font-size: 32px;
  margin-top: 0.3rem;
}
.history-content dt {
  color: #96231c;
  font-size: 48px;
  padding: 20px 0 12px;
}
.history-content dd {
  margin-bottom: 30px;
  max-width: 700px;
}
.history-content .date {
  color: #282828;
  padding-left: 16px;
  position: relative;
  width: 82px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.history-content .date:after {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -4px;
  width: 4px;
  height: 4px;
  border: 2px solid transparent;
  border-top-color: #96231c;
  border-right-color: #96231c;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  left: 0;
}
.history-content .doc-list .date {
  padding-left: 0;
}
.history-content .doc-list .date::after {
  display: none;
}
.history-content .info {
  font-size: 13px;
  line-height: 22px;
  color: #969696;
}
.branch-intro {
  color: #969696;
  font-size: 13px;
  line-height: 22px;
}
.branch-item {
  color: #282828;
  padding-bottom: 10px;
}
.branch-item dt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
}
.branch-item dt .name {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  border: 1px solid #e5e5e5;
  border-right: none;
  padding: 0 0.54rem;
  font-size: 18px;
  line-height: 1rem;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.branch-item dt .icon {
  width: 1.08rem;
  height: 1.08rem;
  background-color: #525252;
  position: relative;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.branch-item dt .icon:before,
.branch-item dt .icon:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 20px;
  height: 2px;
  background-color: #fff;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.branch-item dt .icon:after {
  -webkit-transform: translate(-50%, -50%) rotate(90deg);
          transform: translate(-50%, -50%) rotate(90deg);
}
.branch-item dt.active .name {
  border-color: #f6f6f6;
  background-color: #f6f6f6;
  color: #96231c;
}
.branch-item dt.active .icon {
  background-color: #96231c;
}
.branch-item dt.active .icon:after {
  -webkit-transform: translate(-50%, -50%) rotate(0);
          transform: translate(-50%, -50%) rotate(0);
}
.branch-item dd {
  font-size: 13px;
  line-height: 24px;
  padding: 0.45rem 0 0.37rem 0.54rem;
  display: none;
}
.doc-list.gszl {
  border-top: 1px dotted #e5e5e5;
  margin-top: 0.72rem;
}
.doc-list li {
  padding: 0.3rem 0;
  border-bottom: 1px dotted #e5e5e5;
}
.doc-list .date {
  color: #96231c;
  width: auto;
}
.doc-list .title span {
  display: block;
  display: 'block';
  width: '100%';
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.doc-list .btn {
  width: 0.73rem;
  height: 0.73rem;
  background: url(../../static/images/ximg38.png) no-repeat center;
  background-size: 100%;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  display: none;
}
.doc-list li:hover {
  background: -webkit-gradient(linear, left top, right top, from(#fff), to(#e1e1e1));
  background: linear-gradient(to right, #fff 0%, #e1e1e1 100%);
}
.doc-list li:hover .btn {
  visibility: visible;
  opacity: 1;
}
.partner-list {
  margin-top: 0.538rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.partner-list li {
  border: 1px solid #e5e5e5;
  -width: 2.745rem;
  margin-top: -1px;
  margin-left: -1px;
  width: 33.3%;
}
.join-tip {
  color: #96231c;
  padding: 0.58rem 0 0.25rem;
}
.join-us .card {
  border-top: 3px solid #f6f6f6;
  padding-bottom: 0.4rem;
  font-size: 13px;
}
.join-us .card .subtitle {
  padding: 0.58rem 0 0.25rem;
  color: #282828;
}
.table {
  border-collapse: collapse;
  border-spacing: none;
  width: 100%;
}
.table th,
.table td {
  padding: 0;
  border: 1px solid #dcdcdc;
  text-align: center;
}
.table th {
  padding: 10px;
  font-size: 18px;
  font-weight: 400;
}
.table th.jod-type {
  width: 170px;
  background-color: #96231c;
  color: #fff;
}
.table th.jods {
  width: 240px;
  background-color: #c8c8c8;
}
.table th.zy {
  width: 240px;
  background-color: #dedede;
}
.table th.num {
  width: 120px;
  background-color: #eee;
}
.table td {
  line-height: 20px;
  padding: 18px 0.3rem;
}
.ask-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.ask-list li:nth-child(2n+1) {
  width: 45%;
}
.ask-list li:nth-child(2n) {
  width: 55%;
}
.job-requ .btn {
  margin-bottom: 0.1rem;
  text-align: right;
}
.job-requ .btn a {
  display: inline-block;
  background-color: #96231c;
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  border-radius: 25px;
  margin: 0.72rem 0 0.1rem;
}
.job-requ .btn a:hover {
  text-decoration: underline;
}
.inner-project {
  background: url(../../static/images/ximg40.png) no-repeat left 5.6rem;
  background-size: contain;
  padding-bottom: 2.8rem;
}
.project-header {
  padding-bottom: 0.54rem;
  border-bottom: 3px solid #eaeaea;
}
.search-project {
  border: 1px solid #d6d6d6;
  background-color: #f8f8f8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.search-project input {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  height: 44px;
  line-height: 44px;
  padding-left: 0.3rem;
}
.search-project button {
  width: 1.08rem;
  background: url(../../static/images/ximg02.png) no-repeat center;
}
.pro-list {
  padding-top: 0.53rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  max-width: 360px;
  margin: 0 auto;
}
.pro-list li {
  background-color: #ede7e1;
  padding: 4px;
  margin-bottom: 1.08rem;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.pro-list li .content {
  padding: 0.5rem 0.5rem 0.86rem;
}
.pro-list li .title {
  font-size: 20px;
  padding-bottom: 8px;
  font-weight: 400;
}
.pro-list li .text {
  font-size: 13px;
  color: #8c8986;
  line-height: 24px;
}
.pro-list li:hover {
  background-color: #df5511;
}
.pro-list li:hover .title {
  color: #fff;
}
.pro-list li:hover .text {
  color: #f3c6ba;
}
.load-more {
  display: block;
  margin: 0 auto;
  background-color: #f6f6f6;
  text-align: center;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  width: 4.3rem;
  line-height: 1.08rem;
  height: 1.08rem;
}
.load-more:hover {
  color: #fff;
  background-color: #96231c;
}
.album {
  padding: 0.73rem 0 1.43rem;
}
.album .big-img {
  width: 100%;
  overflow: hidden;
  margin-bottom: 10px;
}
.album .small-img {
  position: relative;
  width: 35%;
  overflow: hidden;
}
.album .small-img .swr-btn {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  z-index: 500;
}
.album .small-img .swr-btn a {
  display: block;
  width: 0.9rem;
  height: 0.9rem;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  position: relative;
}
.album .small-img .swr-btn a:after {
  content: '';
  left: 50%;
  margin-left: -10px;
  position: absolute;
  top: 50%;
  margin-top: -5px;
  width: 8px;
  height: 8px;
  border: 1px solid transparent;
  border-top-color: #fff;
  border-right-color: #fff;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.album .small-img .swr-btn a.prev:after {
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
  margin-left: -2px;
}
.album .small-img .swr-btn a:hover {
  background-color: #96231c;
}
.culture-item {
  padding: 1.22rem 0 0.64rem;
}
.culture-item .title2 {
  color: #96231c;
  font-size: 18px;
  font-weight: 400;
  padding: 10px 0;
}
.culture-item dd {
  font-size: 13px;
  color: #969696;
  line-height: 22px;
}
.culture-item:not(:last-of-type) {
  border-bottom: 3px solid #f4f4f4;
}
.video-show {
  position: relative;
  height: 4.3rem;
  margin-top: 0.53rem;
  cursor: pointer;
  overflow: hidden;
}
.video-show img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%) scale(1, 1);
          transform: translate(-50%, -50%) scale(1, 1);
  min-width: 100%;
  min-height: 100%;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.video-show .text {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: #fff;
  font-size: 20px;
  text-align: center;
}
.video-show .text span {
  margin: 0 10px;
}
.video-show .btn {
  width: 44px;
  height: 44px;
  background: url(../../static/images/ximg42.png) no-repeat center;
  background-size: contain;
  position: absolute;
  bottom: 30px;
  left: 30px;
}
.video-show:hover img {
  -webkit-transform: translate(-50%, -50%) scale(1.2, 1.2);
          transform: translate(-50%, -50%) scale(1.2, 1.2);
}
.search-video {
  margin-bottom: 30px;
  border: none;
  border-bottom: 2px solid #d6d6d6;
}
.video-list {
  padding-bottom: 1.25rem;
}
.video-list li {
  margin-bottom: 0.34rem;
  cursor: pointer;
}
.video-list .img-box {
  position: relative;
}
.video-list .img-box:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3) url(../../static/images/ximg41.png) no-repeat center;
  background-size: 52px;
}
.video-list .name {
  font-size: 16px;
  margin-top: 10px;
  display: 'block';
  width: '100%';
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 900;
  display: none;
}
.popup:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
}
.popup .box {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
.popup .close {
  padding: 0.3rem;
  position: absolute;
  right: 0;
  top: -33px;
  z-index: 1001;
  cursor: pointer;
  background: url(../../static/images/ximg44.png) no-repeat center;
  background-size: 100%;
}
.popup .video {
  width: 100%;
}
.popup .video video {
  width: 100%;
}
.popup .img {
  width: 80%;
}
.top-news {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.top-news .img-box {
  position: relative;
}
.top-news .date {
  color: #fff;
  position: absolute;
  top: 0.53rem;
  left: 0.53rem;
}
.top-news .day {
  font-size: 18px;
  position: relative;
  padding-bottom: 0.1rem;
  margin-bottom: 0.1rem;
}
.top-news .day:after {
  content: '';
  width: 20px;
  height: 2px;
  background-color: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
}
.top-news .year {
  font-size: 16px;
}
.top-news .new {
  background-color: #f5f5f5;
  padding: 0.3rem 0.3rem 1rem;
}
.top-news .title {
  font-size: 16px;
  display: -webkit-box;
  line-height: 28px;
  height: 28px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  font-weight: 400;
  margin-bottom: 5px;
}
.top-news .text {
  color: #969696;
  display: -webkit-box;
  line-height: 22px;
  height: 44px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.top-news .dot {
  text-align: right;
  padding-right: 0.3rem;
}
.top-news .dot span {
  margin: 0 2px !important;
  border-radius: 0;
  width: 4px;
  height: 4px;
  background-color: #7f7f7f;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.top-news .dot span.swiper-pagination-bullet-active {
  width: 12px;
  background-color: #96231c;
}
.top-news:hover .title {
  color: #96231c;
}
.noflex .new {
  background-color: #eaeaea;
  padding: 0.53rem !important;
}
.prev-next {
  top: 25%;
  width: 50px;
  height: 50px;
  background-color: #eaeaea;
  background-image: none;
}
.prev-next:after {
  content: '';
  left: 50%;
  margin-left: -10px;
  position: absolute;
  top: 50%;
  margin-top: -6px;
  width: 10px;
  height: 10px;
  border: 1px solid transparent;
  border-top-color: #96231c;
  border-right-color: #96231c;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.prev-next.new-prev:after {
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
  margin-left: -2px;
}
.prev-next:hover {
  background-color: #96231c;
}
.prev-next:hover:after {
  border-top-color: #fff;
  border-right-color: #fff;
}
.prev-next.new-next {
  right: 0;
}
.prev-next.new-prev {
  left: 0;
}
.new-list {
  padding-bottom: 0.27rem;
}
.new-list li {
  padding: 0.55rem 0 0.34rem;
}
.new-list li:not(:last-of-type) {
  border-bottom: 1px dotted #eee;
}
.new-list .date {
  color: #96231c;
  font-size: 16px;
}
.new-list .title {
  font-weight: 400;
  font-size: 16px;
  padding: 5px 0 10px;
  display: 'block';
  width: '100%';
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.new-list .text {
  color: #969696;
  font-size: 13px;
  display: -webkit-box;
  line-height: 22px;
  height: 44px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.new-list li:hover {
  background-color: #f5f5f5;
}
.new-list li:hover .title {
  color: #96231c;
}
.sw-name {
  font-size: 18px;
  padding: 0 0 0.53rem 0.53rem;
}
.concept-bg {
  background: url(../../static/images/ximg49.jpg) no-repeat bottom;
  background-size: 100%;
}
.tel {
  margin-top: 10px;
  display: block;
  padding: 0.2rem 0 0.2rem 0.9rem;
  background: url(../../static/images/ximg43.png) no-repeat left;
  background-size: 0.76rem;
}
.tel:hover {
  color: #96231c;
  text-decoration: underline;
}
.honor-tab {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0.62rem 0;
}
.honor-tab a {
  color: #c6c6c6;
  position: relative;
}
.honor-tab a:not(:first-of-type) {
  margin-left: 20px;
}
.honor-tab a:not(:first-of-type):after {
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 4px;
  height: 4px;
  background-color: #c6c6c6;
  left: -10px;
  border-radius: 50%;
}
.honor-tab a:hover,
.honor-tab a.active {
  color: #96231c;
}
.honor-list {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
}
.honor-list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.honor-list li {
  padding-bottom: 0.83rem;
  max-width: 176px;
  margin: 0 auto;
  cursor: pointer;
}
.honor-list .img-box {
  padding: 0.58rem 0.5rem;
  border: 1px solid #e5e5e5;
  position: relative;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.honor-list .title {
  font-size: 13px;
  text-align: center;
  margin-top: 14px;
  display: -webkit-box;
  line-height: 20px;
  height: 40px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.honor-list li:hover .img-box {
  border-color: #96231c;
}
.honor-list li:hover .title {
  color: #96231c;
}
.new-header {
  padding: 1.62rem 0 0.36rem;
}
.new-header .title {
  font-weight: 400;
  font-size: 18px;
  padding-bottom: 6px;
}
.new-header .date {
  font-size: 16px;
  color: #96231c;
}
.new-content {
  border-top: 3px solid #fafafa;
  border-bottom: 3px solid #fafafa;
  padding: 0.47rem 0 0.66rem;
}
.new-text {
  font-size: 13px;
  line-height: 22px;
  color: #969696;
  padding-bottom: 22px;
}
.share-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 0.53rem;
}
.share-box .text {
  color: #6e6e6e;
  margin-right: 10px;
}
.share-box .icon {
  margin-right: 6px;
  width: 32px;
  height: 32px;
  border: 1px solid #e9dccd;
  border-radius: 50%;
  background: no-repeat center;
  background-size: 50%;
}
.share-box .icon.wx {
  background-image: url(../../static/images/ximg45.png);
}
.share-box .icon.wb {
  background-image: url(../../static/images/ximg47.png);
}
.share-box .icon.pyq {
  background-image: url(../../static/images/ximg49.png);
}
.share-box .icon.qq {
  background-image: url(../../static/images/ximg51.png);
}
.share-box .icon:hover {
  border-color: #96231c;
  background-color: #96231c;
}
.share-box .icon:hover.wx {
  background-image: url(../../static/images/ximg46.png);
}
.share-box .icon:hover.wb {
  background-image: url(../../static/images/ximg48.png);
}
.share-box .icon:hover.pyq {
  background-image: url(../../static/images/ximg50.png);
}
.share-box .icon:hover.qq {
  background-image: url(../../static/images/ximg52.png);
}
.contact-info {
  position: relative;
  line-height: 24px;
}
.contact-info .info {
  position: absolute;
  top: 0.6rem;
  left: 0.53rem;
}
.contact-info .tx {
  display: block;
  margin-bottom: 10px;
}
.contact-info .tel {
  margin: 3px 0 15px;
}
.contact-info .txdz {
  color: #505152;
}
.contact-info .c7a {
  color: #777a7a;
  margin-bottom: 10px;
}
.contact-info .c7a a {
  color: #777a7a;
}
.contact-info .c7a a:hover {
  text-decoration: underline;
}
