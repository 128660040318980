@media screen and (min-width: 992px) {
  .header {
    padding: 0 0 0 30px;
  }
  .header-logo {
    width: 98px;
  }
  .header-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .header-nav .nav-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 15px;
  }
  .header-nav .nav-list li {
    padding: 10px 0.265rem;
    position: relative;
  }
  .header-nav .sub {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    top: 100%;
    min-width: 120%;
    text-align: center;
    padding: 10px 0;
    background-color: #96231c;
    display: none;
  }
  .header-nav .sub a {
    display: block;
    color: #fff;
    line-height: 30px;
  }
  .header-nav .sub a:hover {
    text-decoration: underline;
  }
  .header.active {
    z-index: 600;
    background-color: transparent;
  }
  .header.active .nav-list {
    visibility: hidden;
    opacity: 0;
  }
  .header.scroll {
    background-color: #fff;
  }
  .header.scroll .menu-icon {
    background-color: #96231c;
  }
  .header .menu-icon {
    width: 80px;
    height: 80px;
    background-color: #96231c;
    padding: 30px 28px;
    z-index: 600;
  }
  .header .menu-icon span {
    background-color: #fff;
  }
  .header .menu-icon.active {
    background-color: #fff;
  }
  .header .menu-icon.active span {
    background-color: #333;
  }
  .header .menu-icon.active span:nth-child(1) {
    -webkit-transform: rotate(45deg) translate(0, 9px);
            transform: rotate(45deg) translate(0, 9px);
  }
  .header .menu-icon.active span:nth-child(3) {
    -webkit-transform: rotate(-45deg) translate(0, -9px);
            transform: rotate(-45deg) translate(0, -9px);
  }
  .inner-layout.footer .main {
    background-color: #29292a;
    display: block;
    padding-top: 50px;
  }
  .inner-layout.footer .footer-nav {
    padding-bottom: 1.74rem;
  }
  .inner-layout.footer .footer-nav p a {
    display: block;
    color: #fff;
    margin-bottom: 22px;
  }
  .inner-layout.footer .footer-nav .sub a {
    display: block;
    color: #a1a1a1;
    margin-bottom: 12px;
  }
  .inner-layout.footer .footer-nav a:hover {
    text-decoration: underline;
  }
  .inner-layout.footer .nav-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .inner-layout.footer .nav-list li {
    width: 127px;
  }
  .inner-layout.footer .company-info {
    margin: 0;
    padding: 50px 0.53rem 0 !important;
  }
  .clear-header {
    height: 80px;
  }
  .in-decorate {
    position: relative;
    margin-top: 0;
  }
  .in-decorate .img-box {
    height: auto;
  }
  .in-decorate .img-box img {
    position: static;
    width: 100%;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  .in-decorate .img-box,
  .in-decorate .content-box {
    float: left;
    width: 50%;
  }
  .in-decorate .content {
    padding-top: 0.3rem;
  }
  .in-decorate .link-list {
    position: absolute;
    bottom: 0.65rem;
    left: 0;
    width: 50%;
  }
  .in-title {
    padding-top: 0.92rem;
  }
  .in-news {
    padding: 0 0.3rem 1.46rem;
  }
  .inner-layout {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .inner-layout .main {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    padding-right: 30px;
  }
  .inner-layout.nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .inner-layout .main-content,
  .inner-layout .inner-nav .nav-list {
    float: right;
    max-width: 770px;
    padding-right: 0;
  }
  .inner-layout .main-content {
    padding-bottom: 2.8rem;
  }
  .inner-layout .slider {
    width: 36.95%;
  }
  .inner-layout.log-nav .main {
    padding-right: 0;
  }
  .inner-layout.log-nav .slider {
    width: 32.65%;
  }
  .inner-layout .crumbs {
    max-width: 680px;
    padding-left: 35px;
  }
  .inner-layout .slider .content {
    padding: 100px 0 86px 0;
  }
  .inner-layout .inchina {
    padding: 46px 30px 0;
  }
  .inner-layout .inchina .title {
    font-size: 18px;
    padding-bottom: 12px;
  }
  .inner-layout .inchina .subtitle {
    font-size: 22px;
    color: #96231c;
    padding-bottom: 10px;
  }
  .inner-layout .china-map2 {
    margin-left: 30px;
  }
  .main-title {
    margin: 90px 0 32px;
  }
  .pro-list li {
    width: 33%;
    max-width: 360px;
  }
  .album {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: start;
  }
  .album .big-img {
    margin-right: 0.53rem;
  }
  .album .small-img {
    width: 48%;
    max-width: 380px;
  }
  .popup .video {
    width: 50%;
  }
  .popup .img {
    width: 30%;
  }
}
